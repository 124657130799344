import React from "react";
import { useState, useEffect } from "react";


import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
} from "recharts";
import Card from "./cards";
import { HorizontalSelect } from "./buttons/dropdown";



export default function GraphRuns({ data }) {


  const [showCard, setShowCard] = useState(false);
  const [playerData, setPlayerData] = useState(data);
  const [currentRow, setCurrentRow] = useState({});
  useEffect(() => {
    setPlayerData(data);
    setShowCard(false);


  }, [data])

  const graphTypeList = [{ 'name': 'Runs Per Match', 'id': 1 }, { 'name': 'Runs Per Season', 'id': 2 }, { 'name': 'Runs Per Position', 'id': 3 }];

  const [graphType, setGraphType] = useState(graphTypeList[0].id);

  const [dataGraph1, setDataGraph1] = useState();
  const [dataGraph2, setDataGraph2] = useState();
  const [dataGraph3, setDataGraph3] = useState();


  useEffect(() => {
    if (playerData.length > 0) {

      const runsPerSeason = {}
      const ballsPerSeason = {}
      const runsPerPosition = {}
      const ballsPerPosition = {}
      playerData.forEach((obj) => {
        const year = obj.Date.split('-')[0]
        if (!runsPerSeason[year]) {
          runsPerSeason[year] = 0
          ballsPerSeason[year] = 0
        }
        if (!runsPerPosition[obj.In_At]) {
          runsPerPosition[obj.In_At] = 0
          ballsPerPosition[obj.In_At] = 0
        }
        runsPerSeason[year] += obj.Runs
        ballsPerSeason[year] += obj.BF
        runsPerPosition[obj.In_At] += obj.Runs
        ballsPerPosition[obj.In_At] += obj.BF

      });


      let temp = [];
      Object.keys(runsPerSeason).forEach((key) => {
        temp.push({ 'Year': key, 'Runs': runsPerSeason[key], 'Balls Faced': ballsPerSeason[key], 'Strike Rate': ((runsPerSeason[key] / ballsPerSeason[key]) * 100).toFixed(2) })

      });
      setDataGraph2(temp);
      temp = [];
      Object.keys(runsPerPosition).forEach((key) => {
        temp.push({ 'Batting Position': key, 'Runs': runsPerPosition[key], 'Balls Faced': ballsPerPosition[key], 'Strike Rate': ((runsPerPosition[key] / ballsPerPosition[key]) * 100).toFixed(2) })

      });
      setDataGraph3(temp);
      setDataGraph1(playerData);





    }
    // if (playerData.length >= 1)

  }, [playerData])






  useEffect(() => {
    try {
      if (currentRow.payload.Id === data[1].Id) {
        setCurrentRow(currentRow.payload)
        setShowCard(true);

      }

    }
    catch (err) {


    }
  }, [currentRow, data])


  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {

      return (
        <div className="custom-tooltip bg-gray-200 rounded-lg px-3 py-3">
          <p className="label">{`${payload[0].value}`}</p>

        </div>
      );
    }

    return null;
  };
  
  const theme = {
    textColor: '#333',
    background: '#fff',
    axis: {
      stroke: '#555',
    },
    grid: {
      stroke: '#ddd',
    },
    tooltip: {
      backgroundColor: '#333',
      color: '#fff',
    },
    // dark mode
    dark: {
      textColor: '#fff',
      background: '#333',
      axis: {
        stroke: '#aaa',
      },
      grid: {
        stroke: '#444',
      },
      tooltip: {
        backgroundColor: '#fff',
        color: '#333',
      },
    },
  };




  return (
    <>
      <div className="flex md:h-full md:min-h-115 h-12 mb-10 text-sm md:mb-0 grid grid-rows-1 grid-flow-col gap-1">


        <div className="">
          <div className="flex md:w-5/12 bg-gray-900 px-2 pt-2 pb-4 rounded-t-lg">
            <HorizontalSelect values={graphTypeList} selectedValues={graphType} setSelectedValues={setGraphType} />
          </div>

        </div>
      </div>
      <div className="flex z-0 md:min-h-10 mt-4 md:mt-0 grid grid-rows-1 grid-flow-col">

        <div className="flex bg-gray-900 rounded-tr-lg py-3">


          {(graphType === 1) && <ResponsiveContainer
            height={350} width="99%" wrapperStyle={{ zIndex: 0 }} theme={theme}>
            <LineChart

              height={300}
              data={dataGraph1}
              theme={theme}
              wrapperStyle={{ zIndex: 0 }}
            >
              <CartesianGrid strokeDasharray="3 3" />

              <YAxis stroke="white" />
              <XAxis stroke="white" />
              <Tooltip content={<CustomTooltip />} wrapperStyle={{ zIndex: 0 }} />
              <Legend />
              <Line
                type="monotone"
                dataKey="Runs"
                stroke="#E7664B"
                fill="white"
                activeDot={{ onClick: (event, payload) => setCurrentRow(payload) }}

              />

            </LineChart>
          </ResponsiveContainer>}

          {(graphType === 2) &&
            <ResponsiveContainer
              height={350} width="99%" >
              <BarChart width={700} height={400} data={dataGraph2}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="Year" stroke="white" />
                <YAxis stroke="white" />
                <Tooltip />
                <Legend />
                <Bar dataKey="Runs" fill="#8884d8" />
                <Bar dataKey="Balls Faced" fill="#82ca9d" />
                <Bar dataKey="Strike Rate" fill="#E7664B" />
              </BarChart>
            </ResponsiveContainer>

          }
          {(graphType === 3) &&
            <ResponsiveContainer
              height={350} width="99%">
              <BarChart width={700} height={400} data={dataGraph3}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="Batting Position" stroke="white" />
                <YAxis stroke="white" />
                <Tooltip />
                <Legend />
                <Bar dataKey="Runs" fill="#8884d8" />
                <Bar dataKey="Balls Faced" fill="#82ca9d" />
                <Bar dataKey="Strike Rate" fill="#E7664B" />
              </BarChart>
            </ResponsiveContainer>

          }


        </div>
      </div>
      <div className={" md:py-0 px-0 py-0 w-full rounded-lg md:mb-5 mt-1 h-30" + (showCard ? " " : " bg-gray-200 dark:bg-gray-800")}>
        {/* <RecentTable data={currentRow}/> */}

        {!showCard && (graphType === 1) && (

          <div className="flex items-center justify-center w-full">
            <p className="animate-pulse text-12xl font-semibold text-gray-900 dark:text-white text-black">Select a point in graph</p>
          </div>
        )}
        {showCard && (graphType === 1) && (
          <div className="flex grid gap-4 grid-flow-row grid-cols-3 md:grid-flow-col md:grid-rows-2 rounded-lg justify-left">




            {Object.keys(currentRow).map((s) => {


              if (s === "Opponent" || s === "6s" || s === "4s" || s === "Venue" || s === "BF" || s === "Win" || s === "Dots" || s === "Team" || s === "Date" || s === "In_At") {

                return (

                  <Card key={s} data={{ title: s, value: currentRow[s] }} />

                )


              }
              else if (s === "Runs") {
                if (currentRow.Out === "Not Out")
                  return (
                    <Card key={s} data={{ title: s, value: String(currentRow[s]) + "*" }} />
                  )
                else
                  return (
                    <div key={s+' div'}>
                      <Card key={s} data={{ title: s, value: currentRow[s] + " - " + currentRow["Out"] + " b " + currentRow["Bowler"] }} className="rounded-none" />
                      
                    </div>
                  )
              }
              else if (s === "Not Out") {

                return (
                  <Card key={s} data={{ title: s, value: String(currentRow[s]) + "*" }} />
                )

              }
              else {
                return (
                  null
                )
              }

            })}




          </div>
        )}

      </div>



    </>

  );
}


export function BowlerGraphs({ bowlerData, format = 20 }) {


  // const runsConcededPerOver = useMemo(() => {
  //   return {};
  // }, [bowlerData]);

  // const wicketsPerYear = useMemo(() => {
  //   return {};
  // }, [bowlerData]);

  // const wicketsPerOver = useMemo(() => {
  //   return {};
  // }, [bowlerData]);

  // const oversBowled = useMemo(() => {
  //   return {};
  // }, [bowlerData]);

  // const [oversBowled, setOversBowled] = useState({})
  // const [wicketsPerOver, setWicketsPerOver]= useState({})
  // const [runsConcededPerOver, setRunsConcededPerOver] = useState({})
  // const [wicketsPerYear, setWicketsPerYear] = useState({})

  const graphTypeList = [{ 'name': 'Economy Per Over', 'id': 1 }, { 'name': 'Wickets Per Year', 'id': 2 }];
  const [graphType, setGraphType] = useState(graphTypeList[0].id);
  const [graphData1, setGraphData1] = useState();
  const [graphData2, setGraphData2] = useState();
  const [playerData, setPlayerData] = useState();


  useEffect(() => {
    if (bowlerData.length > 0)
      setPlayerData(bowlerData);
  }, [bowlerData])

  useEffect(() => {
    const oversBowled = {};
    const wicketsPerOver = {};
    const runsConcededPerOver = {};
    const wicketsPerYear = {};

    try {
      if (playerData.length > 0) {

        for (let i = 1; i < 21; i++) {
          runsConcededPerOver[i] = 0;
          oversBowled[i] = 0;
          wicketsPerOver[i] = 0;

        }



        playerData.forEach(e => {




          const year = e.Date.split('-')[0]

          if (!wicketsPerYear[year])
            wicketsPerYear[year] = 0

          wicketsPerYear[year] += e['Wickets'].length


          for (let i = 0; i < e['Runs'].length; i++) {
            if (!runsConcededPerOver[e['Overs'][i]]) {
              runsConcededPerOver[e['Overs'][i]] = 0;
              oversBowled[e['Overs'][i]] = 0;
            }
            runsConcededPerOver[e['Overs'][i]] += e['Runs'][i]
            oversBowled[e['Overs'][i]] += 1
          }
          for (let i = 0; i < e['Wickets'].length; i++) {
            wicketsPerOver[e['Wickets'][i]] += 1

          }
        }


        )

        let temp = [];
        Object.keys(runsConcededPerOver).forEach((key) => {
          temp.push({ 'Over No.': key, 'Runs': runsConcededPerOver[key], 'Economy': (runsConcededPerOver[key] / oversBowled[key]).toFixed(2), 'Overs Bowled': oversBowled[key], 'Wickets': wicketsPerOver[key] })

        });
        setGraphData1(temp);
        temp = []
        Object.keys(wicketsPerYear).forEach((key) => {
          temp.push({ 'Year': key, 'Wickets': wicketsPerYear[key] })

        });
        setGraphData2(temp);
      

      }
    }
    catch (err) {

    }


  }, [playerData])

  return (
    <>
      <div className="flex md:h-full md:min-h-115 h-10 mb-10 md:mb-0 grid grid-rows-1 grid-flow-col gap-1">


        <div className="">
          <div className="md:w-5/12 bg-gray-800 px-2 pt-2 pb-4 rounded-t-lg">
            <HorizontalSelect values={graphTypeList} selectedValues={graphType} setSelectedValues={setGraphType} />
          </div>

        </div>
      </div>
      <div className="flex z-0 md:min-h-10 mt-4 md:mt-0 grid grid-rows-1 grid-flow-col">

        <div className=" bg-gray-800 rounded-tr-lg py-3">
          {(graphType === 1) &&
            <ResponsiveContainer
              height={350} width="99%" >
              <ComposedChart width={700} height={400} data={graphData1} margin={{
                top: 0,
                right: 20,
                bottom: 0,
                left: 0,
              }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="Over No." stroke="white" />
                <YAxis stroke="white" />
                <Tooltip />
                <Legend />
                <Bar dataKey="Economy" fill="#82ca9d" stroke="#82ca9d" />
                {/* <Bar dataKey="Overs Bowled" fill="#E7664B" /> */}
                <Bar dataKey="Wickets" fill="#E7664B" />
                {/* <Bar dataKey="Overs bowled" fill="#82ca9d" /> */}
                {/* <Bar dataKey="Strike Rate" fill="#E7664B" /> */}
              </ComposedChart>
            </ResponsiveContainer>

          }
          {(graphType === 2) &&
            <ResponsiveContainer
              height={350} width="99%">
              <ComposedChart width={700} height={400} data={graphData2} margin={{
                top: 0,
                right: 20,
                bottom: 0,
                left: 0,
              }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="Year" stroke="white" />
                <YAxis stroke="white" />
                <Tooltip />
                <Legend />

                <Bar dataKey="Wickets" fill="#8884d8" />
                {/* <Bar dataKey="Overs bowled" fill="#82ca9d" /> */}
                {/* <Bar dataKey="Strike Rate" fill="#E7664B" /> */}
              </ComposedChart>
            </ResponsiveContainer>

          }

        </div>

      </div>




    </>


  )

}

import { Transition, Popover } from '@headlessui/react';
import { Fragment } from 'react';
import { useState } from 'react';
import { RadioGroup } from '@headlessui/react';

export default function DropDownPanel({title, values, selectedValues, setSelectedValues, onClick}) {

    const list =  values ?? ['Option 1', 'Option 2'];
    selectedValues = selectedValues ?? values;
    onClick = onClick ?? ((option) => {


        
        let newFilter = [...selectedValues];
        if (newFilter.includes(option)) {
            newFilter = newFilter.filter((item) => item !== option);
        }
        else {

            newFilter.push(option);
        }
        
        setSelectedValues(newFilter);
        
    })
    
    

    return (
        <Popover as="div" className="w-full h-full inherit relative inline-block content-center">
            {({ open }) => (
                <>

                    <div>
                        <Popover.Button className="inline-flex h-full w-full align-middle justify-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 ">
                            {(title) ? title: "Button"}
                            {/* <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" /> */}
                        </Popover.Button>
                    </div>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Popover.Panel static className="absolute  w-20 max-h-60 overflow-y-auto right-0 z-10 mt-2 w-auto origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="flex sticky  py-1 mx-2 ">

                                <div
                                    onClick={() => setSelectedValues([])}
                                    href="#"
                                    className=
                                        'bg-gray-300 hover:bg-gray-100 text-gray-900 flex block px-4 mx-1 py-2 text-sm rounded-lg'
                                    
                                >   <span className="inline-block mr-2">


                                        <p>None</p>
                                    </span>


                                </div>
                                <div
                                    onClick={() => setSelectedValues([...values])}
                                    href="#"
                                    className=
                                        'bg-gray-300 hover:bg-gray-100 text-gray-900 flex block px-4 mx-1 py-2 text-sm rounded-lg'
                                
                                >   <span className="inline-block mr-2">


                                        <p>All</p>
                                    </span>


                                </div>


                            </div>

                            {list.map((item) => (

                                <div key={item} className="flex py-1">

                                    <div key={item+"1"}
                                        onClick={() => onClick(item)}
                                        href="#"
                                        className=
                                            'hover:bg-gray-100 hover:text-gray-900 flex text-gray-700 block px-4 py-2 text-sm flex'
                                
                                    >   <span key={item+"s"} className="inline-block mr-2">


                                            <p key={item+"p"}>{item}</p>

                                        </span>
                                        {(selectedValues.includes(item)) && (
                                            <span className="inline-block">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-4 h-4">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /> abc
                                                </svg>

                                            </span>
                                        )}

                                    </div>


                                </div>
                            ))}


                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    )
}




const options = [
  {
    name: 'Startup',
    ram: '12GB',
    cpus: '6 CPUs',
    disk: '160 GB SSD disk',
  },
  {
    name: 'Business',
    ram: '16GB',
    cpus: '8 CPUs',
    disk: '512 GB SSD disk',
  },
  {
    name: 'Enterprise',
    ram: '32GB',
    cpus: '12 CPUs',
    disk: '1024 GB SSD disk',
  },
]

export function HorizontalSelect({values, selectedValues, setSelectedValues}) {
  const [selected, setSelected] = useState(options[0])
  selectedValues = selectedValues ?? selected;
  setSelectedValues = setSelectedValues ?? setSelected;
  values = values ?? options;



  return (
    <RadioGroup value={selectedValues} onChange={setSelectedValues}>
  <RadioGroup.Label className="sr-only">Choose an option</RadioGroup.Label>
  <div className="flex items-center justify-between grid grid-rows-1 grid-flow-col">
  
    {values.map((option) => (
      <RadioGroup.Option
        key={option.id}
        value={option.id}
        className={({ checked }) =>
          `  hover:bg-green-400 px-4 py-2 rounded-md ${
            checked ? 'bg-green-400' : 'bg-gray-200'
          }`
        }
      >
        {({ checked }) => (

          <>
          
            <span className={(checked ? 'text-bold translate-x-6' : 'text-sm translate-x-6')}>
              {option.name}
            </span>
           
          </>
        )}

      </RadioGroup.Option>
    ))}

  </div>
</RadioGroup>

  )
}





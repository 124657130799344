
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Loading from "./loading";

const TeamList = ({teams}) => {
    
    const [teamData, setTeamData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        const loadTeamData = async() => {
            setIsLoading(true);
            const response = await axios.get(`/api/teams`)
            const newData = response.data;
            //console.log(newPlayerData);
            setTeamData(newData); 
            setIsLoading(false);
        } 
        loadTeamData(); 
        
    }, [])

    const navigate = useNavigate();

  const handleClick = (id, name) => {
    navigate('/dashboard', { state: { id, name } });
  };


    return (
        <>
            {isLoading && <div className="flex justify-center align-middle min-h-screen">
                    <Loading/>
                </div>}
    
            {teamData.map(team => (
                <div key={team.name} className="shadow-md ... p-20 hover:bg-gray-800">
                <h1 className="mb-1 text-1xl font-extrabold text-white md:text-6xl lg:text-4xl pb-5">{team.name}</h1>
                <ul className="md:columns-3 columns-2 gap-15">
                {team.players.map(player => (
                    
                        <li key= {player.name} onClick={() => handleClick(player.id, player.name)} className="text-2xl font-thin text-white hover:text-3xl">
                           
                            
                            {player.name}

                            </li>
                    
                ))}
                </ul>
                
                </div>

            ))}
    
        </>
    );

}

export default TeamList;
import { Link } from "react-router-dom";
import DashboardGif from './Dashboard.gif';
import VisitLogger from "../components/log";

export default function HomePage() {

    return (
        <div className="bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 md:min-h-screen min-h-screen">
            <VisitLogger/>

            <div className="flex align-middle justify-center grid md:grid-cols-2 md:grid-row-flow grid-cols-1 gap-1 md:py-32">

                <div className="flex  px-5 py-2 align-middle  justify-center max-h-56 md:h-auto h-auto md:h-4/5 md:max-h-fit">
                    <Link to={"/dashboard"}>
                        <img src={DashboardGif} alt="Your GIF alt text" className="rounded-lg" style={{ height: '80%', maxWidth: '100%' }} />
                    </Link>
                </div>
                <div className="px-5  h-auto md:h-4/5 shadow-md ...  align-middle font-thin text-white dark:text-white  justify-center">
                    <Link to={"/dashboard"}>
                        <p className="text-2xl font-bold pb-5 animate-pulse">Player Dashboard</p>
                    </Link>
                    <p className="py-1">Welcome to CricDash. With Player Dashboard you can easily view and analyze the performance of cricket players in a visually appealing way. With our intuitive design and powerful features, you can quickly filter and sort data to get valuable insights into each player's strengths and weaknesses.</p>
                    <p className="py-1">Our dashboard offers a range of features to help you explore player statistics and gain a deeper understanding of their performance. You can easily view detailed performance metrics for each player, and track their progress over time.</p>
                    <p className="py-1"> With our cricket player stats dashboard, you'll have all the tools you need to stay on top of your game and make data-driven decisions. Explore the performance of your favorite players in a whole new way, and take your cricket insights to the next level.</p>
                </div>
            </div>
        </div>
    )

}
import { useState } from "react";

export default function Demo() {
    const toggleClass = " md:transform translate-x-14 transform translate-x-14";
    const [isBatSwitch, setIsBatSwitch] = useState(true);

    return (
        <div className="bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-lg md:mx-10">


            <div className="flex grid md:grid-rows-2 md:grid-cols-4 grid-cols-1 gap-1">

            <div className="flex align-middle justify-center py-10 ">
                    <div className="w-32 max-h-10 align-middle justify-center  mx-auto bg-white rounded-lg  ring-gray-300 hover:bg-gray-50  ring-1 ring-inset inline-flex h-full rounded-md px-3 py-2 text-sm font-semibold text-gray-900">
                        Match Range

                    </div>
                </div>
                <div className="px-10  py-10  ...  align-middle font-thin text-white  text-2xl dark:text-white  justify-center md:animate-pulse">
                Filters stats for any range of games played
                </div>
                
                <div className="flex align-middle justify-center px-10 py-10">
                <div
                    className="w-32 md:h-8 flex justify-center align-middle max-h-10  flex items-center dark:bg-white bg-gray-300 rounded-lg p-1 cursor-pointer"
                    onClick={() => {
                        setIsBatSwitch(!isBatSwitch);
                    }}
                >

                    <div
                        className={
                            " bg-black md:w-16 md:h-6 h-6 w-16 text-gray-200 rounded-lg shadow-md  bg-blend-exclusion" +
                            (isBatSwitch ? null : toggleClass)
                        }
                    >
                        {isBatSwitch ? "Bat" : "Bowl"}



                    </div>
                    <div className={
                        "md:w-16 md:h-6 h-6 w-16 text-gray-500 bg-blend-exclusion" +
                        (isBatSwitch ? null : "md:transform -translate-x-14 transform -translate-x-14")
                    }>
                        {isBatSwitch ? "Bowl" : "Bat"}
                    </div>

                </div>
                </div>
                <div className="px-10  py-10  ...  align-middle font-thin text-white  text-2xl dark:text-white  justify-center md:animate-pulse">
                    Switch tabs to view batting or bowling stats
                </div>
                
                <div className="flex align-middle justify-center py-10 ">
                    <div className="w-32 max-h-10 align-middle justify-center  mx-auto bg-white rounded-lg  ring-gray-300 hover:bg-gray-50  ring-1 ring-inset inline-flex h-full rounded-md px-3 py-2 text-sm font-semibold text-gray-900">
                        Venue

                    </div>
                </div>
                <div className="px-10 py-10  ...  align-middle font-thin text-white  text-2xl dark:text-white  justify-center md:animate-pulse">
                    Filter stats pertaining to a venue
                </div>
                <div className="flex align-middle justify-center py-10 ">
                    <div className="w-32 max-h-10 align-middle justify-center  mx-auto bg-white rounded-lg  ring-gray-300 hover:bg-gray-50  ring-1 ring-inset inline-flex h-full rounded-md px-3 py-2 text-sm font-semibold text-gray-900">
                        Opponent

                    </div>
                </div>
                <div className="px-10 py-10  ...  align-middle font-thin text-white  text-2xl  dark:text-white  justify-center md:animate-pulse">
                    Filter stats against a single or multiple opponents
                </div>
                
                <div className="flex align-middle justify-center py-10 ">
                    <div className="w-32 max-h-10 align-middle justify-center  mx-auto bg-white rounded-lg  ring-gray-300 hover:bg-gray-50  ring-1 ring-inset inline-flex h-full rounded-md px-3 py-2 text-sm font-semibold text-gray-900">
                        Filter By

                    </div>
                </div>
                <div className="px-10 py-10 ...  align-middle font-thin text-white text-2xl dark:text-white  justify-center md:animate-pulse">
                    Filter Stats by wins and innings
                </div>

            </div>
        </div>
    )

}
import { useState, useEffect } from 'react';
import axios from 'axios';

function VisitLogger() {
  const [ip, setIp] = useState(null);

  useEffect(() => {
    async function logVisit() {
      const url = window.location.href;
      await axios.post('/api/log_visit', { url, ip });
    }

    if (ip) {
      logVisit();
    }
  }, [ip]);

  useEffect(() => {
    async function fetchIp() {
      const response = await axios.get('https://api.ipify.org?format=json');
      setIp(response.data.ip);
    }

    fetchIp();
  }, []);

  return null;
}

export default VisitLogger;

import Card from "./cards";
import { BowlerGraphs } from "./Graph";

export default function BowlingPage({ isOpen, bowlerData }) {
    let MetaData = [{ 'title': 'Matches Bowled', 'value': bowlerData.length }];
    let wickets = 0;
    let runs = 0;
    let overs = 0;
    let sixes = 0;
    let fours = 0;
    let threeWicketHaul = 0;
    let fiveWicketHaul = 0;
    let date = "Unknown";
    const runsConcededPerOver = {}
    const oversBowled = {}


    bowlerData.forEach(e => {

        try {
            wickets += e['Wickets'].length;
            // runs += e['Runs'].reduce((total, num) => total + num);
            overs += e['Runs'].length;
            sixes += e['6s'];
            fours += e['4s'];
            if (e['Wickets'].length >= 3 && e['Wickets'].length < 5)
                threeWicketHaul += 1
            if (e['Wickets'].length >= 5)
                fiveWicketHaul += 1
        }
        catch (err) {

        }
        for (let i = 0; i < e['Runs'].length; i++) {
            runs += e['Runs'][i]
            if (!runsConcededPerOver[e['Overs'][i]]) {
                runsConcededPerOver[e['Overs'][i]] = 0;
                oversBowled[e['Overs'][i]] = 0;
            }
            runsConcededPerOver[e['Overs'][i]] += e['Runs'][i]
            oversBowled[e['Overs'][i]] += 1
        }


    })



    try { date = bowlerData.slice(-1)[0]['Date']; }
    catch {
        date = 'Unknown';
    }

    MetaData.push({ 'title': 'Wickets Taken', 'value': wickets })
    MetaData.push({ 'title': 'Economy', 'value': (runs / overs).toFixed(2) })
    MetaData.push({ 'title': 'Average', 'value': (runs / wickets).toFixed(2) })
    MetaData.push({ 'title': 'Strike Rate', 'value': ((overs * 6) / wickets).toFixed(2) })
    MetaData.push({ 'title': 'Three Wicket Haul', 'value': threeWicketHaul })
    MetaData.push({ 'title': 'Five Wicket Haul', 'value': fiveWicketHaul })
    MetaData.push({ 'title': '6s Conceded', 'value': sixes })
    MetaData.push({ 'title': '4s Conceded', 'value': fours })
    MetaData.push({ 'title': 'Last Played', 'value': date })


    return (

        <div>
            {bowlerData.length === 0 &&

                <p className="text-gray-500 text-3xl font-bold">No data found 😢</p>

            }

           




            {
                bowlerData.length > 0 &&
                <>
                    <div className="grid grid-rows-5 md:grid-rows-2 grid-flow-col gap-1 overflow-x-auto mb-2">
                        {
                            MetaData.map(row => (
                                <div key={row.title}>
                                    <Card data={row} />
                                </div>
                            ))
                        }

                    </div>
            

            {isOpen && (

                <div className={"invisible md:visible"}>

                    <BowlerGraphs bowlerData={bowlerData} />



                </div>

            )}
            {!isOpen && (

                <div className={"visible md:visible"}>


                    <BowlerGraphs bowlerData={bowlerData} />


                </div>

            )}
            </>
            }

        </div>
    )





}
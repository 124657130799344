
export default function AboutPage() {

    return (
        <>
        <div className="bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 flex flex-col min-h-screen py-10">
            <div className="md:px-10 px-10">
                <div className="grid md:grid-cols-1 md:grid-flow-row md:gap-5 gap-2 w-full">
                    <div className="max-w-3xl mx-auto my-auto align-middle place-content-center ">

                        <h1 className="mb-1 text-3xl font-extrabold text-white md:text-3xl lg:text-4xl pb-1 animate-pulse">About Project CricDash</h1>

                        
                    </div>
                    <div className="max-w-6xl mx-auto shadow-md">

                        <div className="text-2xl font-thin text-white text-white">
                            <p className="mb-4 ">Project CricDash is intended to make cricket statistics interesting for cricket fans. The database is currently updated on a weekly basis, and more data will be added for other tournaments in the future.</p>
                            
                            <p className="mb-4 ">If you have any feedback, suggestions, or would like to request additional features, please reach out to the site owner.</p>
                        </div>

                    </div>


                    <div className="max-w-3xl mx-auto my-auto align-middle place-content-center">

                        <h1 className="mb-1 text-3xl font-extrabold text-white md:text-3xl lg:text-4xl pb-1 animate-pulse">Upcoming Features</h1>


                    </div>

                    <div className="max-w-6xl mx-auto">

                        <div className="text-2xl font-thin text-white">
                            <p className="mb-4 text-justify"></p>
                            <p className="mb-4 ">The upcoming version of the website will include new features, which will be rolled out in future updates, to make results that require complex queries easily accessible via a search function.</p>
                            <p className="mb-8 ">The future version of the website will also have a Teamboard page, which will serve as a dashboard for team-related data.</p>
                        </div>

                    </div>



                </div>
            </div>
            
        </div>
        <footer className="py-4 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 text-gray-100 text-center sticky bottom-0 h-16">
            <div className="flex justify-center space-x-4">
                            <p className="place-content-center  text-xl font-thin ">By Ramkumar Sivakumar</p>
                            <a className="mx-auto" href="https://www.linkedin.com/in/ramkumar1998/" target="_blank" rel="noreferrer">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" viewBox="0 0 24 24" fill="currentColor">
                                    <path d="M19.4 4H4.6C3.5 4 3 4.5 3 5.6v12.8c0 1.1.5 1.6 1.6 1.6h14.8c1.1 0 1.6-.5 1.6-1.6V5.6c0-1.1-.5-1.6-1.6-1.6zM8.5 16.5h-2v-7h2v7zM7.5 8.5c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.7 1.5-1.5 1.5zM17.4 16.5h-2.1v-3c0-.8 0-1.9-1.1-1.9-1.1 0-1.3.8-1.3 1.8v3.1h-2v-7h2v.9c.4-.7 1.2-1.4 2.4-1.4 2.6 0 3.1 1.7 3.1 3.9v4.6z" />
                                </svg>
                            </a>
                            <a className="mx-auto" href="https://ramsrk7.github.io" target="_blank" rel="noreferrer">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 pt-1" fill="none" viewBox="0 0 24 24"  stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418" />
                                </svg>

                            </a>
                            

                        </div>
            </footer>
        </>

    )
}
import GraphRuns from "./Graph";
import Card from "./cards";

const BattingStats = ({ playerData, isOpen, metaData }) => {


    const matchesPlayed = playerData.length;
    let L = "Chennai Super Kings";
    let MetaData = [];

    let runs = 0;
    let notOut = 0;
    let sixes = 0;
    let fours = 0;
    let ballsFaced = 0;
    let dots = 0;
    let inAt = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0 }
    let runsPos = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0 }
    playerData.map(e => runs += e.Runs);

    playerData.forEach((e) => {
        if (e.Out !== "Not Out") {
            notOut += 1
        }
        sixes += e['6s'];
        fours += e['4s'];
        ballsFaced += e['BF']
        dots += e['Dots']
        inAt[e['In_At']] += 1
        runsPos[e['In_At']] += e['Runs']

        playerData.slice(-1).forEach(e => {
            L = e.Date;
        })
    });

    let maxInAt = null;
    let maxValue = -Infinity;

    for (const [key, value] of Object.entries(inAt)) {
        if (value > maxValue) {
            maxInAt = key;
            maxValue = value;
        }
    }

    let maxRunsAtPos = null;
    maxValue = -Infinity;

    for (const [key, value] of Object.entries(runsPos)) {
        if (value > maxValue) {
            maxRunsAtPos = key;
            maxValue = value;
        }
    }







    let average = runs / notOut;

    MetaData.push({ 'title': 'Matches Batted', 'value': matchesPlayed });
    MetaData.push({ 'title': 'Average', 'value': average.toFixed(2) });
    MetaData.push({ 'title': 'Sixes', 'value': sixes });
    MetaData.push({ 'title': 'Fours', 'value': fours });
    MetaData.push({ 'title': 'Last Played', 'value': L });
    MetaData.push({ 'title': 'Runs', 'value': runs });
    MetaData.push({ 'title': 'Strike Rate', 'value': ((runs / ballsFaced) * 100).toFixed(2) });
    MetaData.push({ 'title': 'Average Dot Balls', 'value': (dots / matchesPlayed).toFixed(2) });
    MetaData.push({ 'title': 'Position Most Batted', 'value': maxInAt });
    MetaData.push({ 'title': 'Best Position', 'value': String(runsPos[maxRunsAtPos]) + " Runs at No." + String(maxRunsAtPos) });




    return (
        <div className="min-h-full">

            {matchesPlayed === 0 && 

<p className="text-gray-500 text-3xl font-bold">No data found 😢</p>

}



            {matchesPlayed > 0 &&

                <div className=" visible">




                    {playerData.length > 0 &&
                        <div className="grid grid-rows-5 md:grid-rows-2 grid-flow-col gap-1 overflow-x-auto mb-2">
                            {
                                MetaData.map(row => (
                                    <div key={row.title}>
                                        <Card data={row} key={row.title + "1"} />
                                    </div>
                                ))
                            }

                        </div>
                    }




                    {isOpen && (

                        <div className={"invisible md:visible"}>


                            <GraphRuns data={playerData} />


                        </div>

                    )}
                    {!isOpen && (

                        <div className={"visible md:visible"}>


                            <GraphRuns data={playerData} />


                        </div>

                    )}






                </div>
            }
        </div>

    )

}


export default BattingStats;
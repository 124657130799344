import {BrowserRouter, Routes, Route} from 'react-router-dom';
import './App.css';
import NavBar from './navbar';
import PlayerListPage from './pages/playerList';
import DashboardHome from './pages/DashboardNav';
import HomePage from './pages/Home';
import AboutPage from './pages/About';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
      <header className="sticky top-0 z-50">
        <NavBar/>
        </header>
        <Routes>
          <Route path="/" element={<HomePage/>}/>
          <Route path="/players" element={<PlayerListPage/>}/>
          <Route path="/dashboard" element={<DashboardHome/>}/>
          <Route path="/about" element={<AboutPage/>}/>
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;

import React from 'react';

const Loading = () => {
  return (
    <div className="flex items-center justify-center">
      <svg className="animate-spin h-10 w-10 text-gray-500" viewBox="0 0 24 24">
        <path d="M4.93 15.06A8 8 0 0 0 20.12 9a1 1 0 0 1-.86 1.8 6 6 0 1 1-8.48-6.48 1 1 0 0 1-1.8.86 8 8 0 0 0-4.05 11.68z"/>
      </svg>
    </div>
  );
};

export default Loading;

import TeamList from "../components/TeamList";



const PlayerListPage = () => {

    const tournament = "Indian Premier League";

    return (
        <div className="bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 min-h-screen">
        <div className=" box-border hover:box-content">
        <TeamList teams={tournament}/>
            
        </div>
        

        </div>
    )
}

export default PlayerListPage;
import { useState, useEffect } from "react";
import { Fragment } from 'react'
import {useSwipeable} from 'react-swipeable';
import axios from "axios";
import { useLocation } from 'react-router-dom';
import { Menu, Transition, Popover } from '@headlessui/react'
import Loading from "../components/loading";
import BattingStats from "../components/Batting";
import Card from "../components/cards";
import Search from "../components/search";
import RangeSlider from "../components/Slider";
import DropDownPanel from "../components/buttons/dropdown";
import BowlingPage from "../components/Bowling";
import { XMarkIcon } from '@heroicons/react/24/outline';
import VisitLogger from "../components/log";
import Demo from "./DashboardDemo";





const DashboardHome = (props) => {
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }



    const location = useLocation();
    const { id, name } = location.state ? location.state : {id:"", name:""};





    const [opponents, setOpponents] = useState([]);
    const [venues, setVenues] = useState([]);
    const [filterOpponents, setFilterOpponents] = useState([]);
    const [playerData, setPlayerData] = useState([{ Team: "" }]);
    const [originalPlayerData, setOriginalPlayerData] = useState([]);
    const [batFirst, setBatFirst] = useState(true);
    const [batSecond, setBatSecond] = useState(true);
    const [win, setWin] = useState(true);
    const [lose, setLose] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isBatSwitch, setIsBatSwitch] = useState(true);
    const [tournamentOpen, setTournamentOpen] = useState(false);
    const [matchRange, setMatchRange] = useState([0, 0]);
    const [originalMatchRange, setOriginalMatchRange] = useState([0, 0]);



    const [currentPlayer, setCurrentPlayer] = useState(id !== "" ? { name: "", alias: "", id: "" } : { name: "", alias: "", id: "" });

    const changePlayer = (player) => {
        setCurrentPlayer(player);
   
        const screenWidth = window.innerWidth;
        if(screenWidth < 768)
            setIsOpen(false); // adjust this value to match your breakpoint



    };


    const handleComponentSelect = (component) => {

        setCurrentPlayer({ name: component[1], alias: "", id: component[0] })
    };
    const handleSlider = (component) => {

        setMatchRange(component);

    };

    const toggleClass = " md:transform translate-x-14 transform translate-x-14";
    // const resetFilter = useCallback(() => {
    //     if (batFirst) {
    //         setBatFirst(false);
    //     }

    //     if (batSecond) {
    //         setBatSecond(false);
    //     }

    //     if (win) {
    //         setWin(false);
    //     }

    //     if (lose) {
    //         setLose(false);
    //     }
    // }, [batFirst, batSecond, win, lose]);

    useEffect(() => {
       
        if (currentPlayer.id === "")
            setCurrentPlayer({ name: name, alias: "", id: id });
        const loadPlayerData = async () => {
            setIsLoading(true);
            if (currentPlayer.id !== "" && isBatSwitch === true) {
                const response = await axios.get(`/api/player/stats/${currentPlayer.id}/bat`,
                    { params: { bat: 1 } })
                const newPlayerData = response.data.slice().reverse();
                setPlayerData(newPlayerData);
                setOriginalPlayerData(newPlayerData);
                // resetFilter();
                setIsLoading(false);

                setOpponents([...new Set(newPlayerData.map(item => item.Opponent))]);
                setFilterOpponents([...new Set(newPlayerData.map(item => item.Opponent))]);
                setVenues([...new Set(newPlayerData.map(item => item.Venue))]);
                setMatchRange([0, newPlayerData.length]);
                setOriginalMatchRange([0, newPlayerData.length]);
            }

            //Bowling Data
            if (isBatSwitch === false) {
                const response2 = await axios.get(`/api/player/stats/${currentPlayer.id}/bowl`);
                const newPlayerData2 = response2.data.reverse();
                setPlayerData(newPlayerData2);

                setOriginalPlayerData(newPlayerData2);
                // resetFilter();
                setOpponents([...new Set(newPlayerData2.map(item => item.Opponent))]);
                setFilterOpponents([...new Set(newPlayerData2.map(item => item.Opponent))]);
                setVenues([...new Set(newPlayerData2.map(item => item.Venue))]);
                setMatchRange([0, newPlayerData2.length]);
                setOriginalMatchRange([0, newPlayerData2.length]);
                setIsLoading(false);
            }


        }
        loadPlayerData();
    }, [currentPlayer.name, isBatSwitch, currentPlayer.id, id, name])
  

    const [metaData, setMetaData] = useState("");
    const [isLoadingMeta, setIsLoadingMeta] = useState(false);

    useEffect(() => {
        const loadMetaData = async () => {

            if (currentPlayer.id !== "") {
                setIsLoadingMeta(true);

                const response = await axios.get(`/api/player/info/${currentPlayer.id}`,
                    { params: { bat: 1 } })
                const data = response.data;
                setMetaData(data);
                setIsLoadingMeta(false);


            }
        }
        loadMetaData();
    }, [currentPlayer.id])



    //const {articleId} = params;
    //const {articleId} = useParams();


    const [teamData, setTeamData] = useState([]);
    const [isListLoading, setIsListLoading] = useState(false);

    useEffect(() => {
        const loadTeamData = async () => {
            setIsListLoading(true);
            const response = await axios.get(`/api/teams`)
            const newData = response.data;


            setTeamData(newData);
            setIsListLoading(false);


        }
        loadTeamData();
    }, [])







    const [isDropdownOpen, setIsDropdownOpen] = useState(true);
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);

    };

    const [isOpen, setIsOpen] = useState(true);






    const handleOpponentFilter = (opponent) => {

        let newFilter = [...filterOpponents];
        if (newFilter.includes(opponent)) {
            newFilter = newFilter.filter((item) => item !== opponent);
        }
        else {

            newFilter.push(opponent);
        }


        setFilterOpponents(newFilter);

    }

    useEffect(() => {


        const filterData = () => {
            let data = [...originalPlayerData]
            data = data.slice(matchRange[0], matchRange[1] + 1);
            data = data.filter(item => filterOpponents.includes(item.Opponent));
            data = data.filter(item => venues.includes(item.Venue));



            if (batFirst === true && batSecond === true && win === true && lose === true) {
                // setPlayerData(data);
            }

            else if (batFirst === true && batSecond === false) {

                data = data.filter((item) => item.Innings === 1);
            }
            else if (batFirst === false && batSecond === true) {

                data = data.filter((item) => item.Innings === 2);
            }


            if (win === true && lose === false) {
                data = data.filter((item) => item.Win === 'Yes');
            }
            else if (win === false && lose === true) {

                data = data.filter((item) => item.Win === 'No');
            }


            setPlayerData(data);

        }
        filterData();
    }, [filterOpponents, batFirst, batSecond, win, lose, matchRange, venues, originalPlayerData])



    function getActiveClass(x) {

        return x;
    }

    const [lastUpdated, setLastUpdated] = useState("");
    useEffect(() => {
        const Date = async () => {
            try {
                const response = await axios.get(`/api/lastupdate`);
                setLastUpdated(response.data['timestamp']);
            } catch (error) {
                setLastUpdated("");
            }
        }
        Date();

    }, [])

    const handlers = useSwipeable({
  
  
        //onSwipedRight: () => setIsOpen(true),
        onSwipedLeft: () => setIsOpen(false),
        // ignoredElements: ['.ignore-swipe'],
        preventDefaultTouchmoveEvent: true,
        onSwiping: (eventData) => {
            if (eventData.deltaX < -50) { // Only close if swiped more than 50 pixels to the left
              setIsOpen(false);
            } else if (eventData.deltaX > 80) { // Only open if swiped more than 50 pixels to the right
              setIsOpen(true);
            }
          }
        }
       
       

        //   trackMouse: false // Disable swiping up and down
      );

    const handleSliderChange = (event) => {
        //event.stopPropagation(); // Add this line to prevent the swipeable sidebar from opening
      };

      function isMobile() {
        const screenWidth = window.innerWidth;
        return screenWidth < 768; // adjust this value to match your breakpoint
      }
  





    return (
        <>
            <VisitLogger />
            <div key={1} {...handlers}>

                <div className={"overflow-auto z-40 transform transition-transform ease-in-out duration-300 " + (currentPlayer.name === "" || isLoading) ? "md:min-h-screen min-h-full" : "h-full"}>
                    <div className="relative min-h-screen" >

                        <div className="absolute inset-0 flex flex-wrap overflow-hidden dark:bg-stone-700 " aria-hidden="true">
 
                             <div className="w-1/12 h-full transform -skew-x-12 dark:bg-stone-700"></div>
                            <div className="w-1/12 h-full transform -skew-x-12 dark:bg-stone-700"></div>
                            <div className="w-1/12 h-full transform -skew-x-12 dark:bg-stone-700"></div>
                            <div className="w-1/12 h-full transform -skew-x-12 bg-yellow-500"></div>
                            <div className="w-1/12 h-full transform -skew-x-12 bg-blue-500"></div>
                            <div className="w-1/12 h-full transform -skew-x-12 bg-red-500"></div>

                            <div className="w-1/12 h-full transform -skew-x-12 bg-stone-500 hidden md:flex"></div>
                            <div className="w-1/12 h-full transform skew-x-12 bg-sky-800 hidden md:flex"></div>
                            <div className="w-1/12 h-full transform skew-x-12 bg-pink-500"></div>
                            <div className="w-1/12 h-full transform skew-x-12 bg-red-700"></div>
                            <div className="w-1/12 h-full transform skew-x-12 bg-amber-500"></div>
                            <div className="w-1/12 h-full transform skew-x-12 dark:bg-stone-700"></div> 

                        </div>

                        <div className="relative" >
                            <header className="sticky top-16 z-10">
                                <div as="nav" className="flex md:hidden bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 px-2 py-2 opacity-90">
                                    <div onClick={() => setIsOpen(!isOpen)} className="inline-flex items-center justify-center rounded-md p-2 text-gray-200 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white animate-pulse">
                                        <span className="sr-only">Open main menu</span>
                                        {isOpen ? (
                                            <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                        ) : (
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                                <path fillRule="evenodd" d="M2 10a8 8 0 1116 0 8 8 0 01-16 0zm8 1a1 1 0 100-2 1 1 0 000 2zm-3-1a1 1 0 11-2 0 1 1 0 012 0zm7 1a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                                            </svg>

                                        )}
                                        {" "}Menu
                                    </div>
                                    <div
                                        className="md:w-32 md:h-8 w-32 ml-auto h-9 flex items-center bg-gray-400 rounded-lg p-1 cursor-pointer"
                                        onClick={() => {
                                            setIsBatSwitch(!isBatSwitch);
                                        }}
                                    >

                                        <div
                                            className={
                                                "bg-black md:w-16 md:h-6 h-6 w-16 text-gray-200 rounded-lg shadow-md transform duration-300 ease-in-out bg-blend-exclusion" +
                                                (isBatSwitch ? null : toggleClass)
                                            }
                                        >
                                            {isBatSwitch ? "Bat" : "Bowl"}

                                        </div>
                                        <div className={
                                            "z-0 md:w-16 md:h-6 h-6 w-16 text-gray-500 transform duration-300 ease-in-out bg-blend-exclusion" +
                                            (isBatSwitch ? null : "md:transform -translate-x-14 transform -translate-x-14")
                                        }>
                                            {isBatSwitch ? "Bowl" : "Bat"}
                                        </div>


                                    </div>

                                </div>
                            </header>






                            {isOpen && (




                                <div className={"z-10 md:visible overflow-auto z-40 transform transition-transform ease-in-out duration-300 " + isOpen ? "md:fixed w-8/12  md:w-2/12 overflow-x-auto fixed visible" : "md:fixed w-6/12  md:w-2/12 overflow-x-auto md:visible hidden" }
                                style={{ transform: `translateX(${isOpen ? 0 : '-100%'})` }}>

                                    {/* <VerticalNav data={playerName}/> */}





                                    <aside className={"z-10 overflow-x-auto flex flex-col inset-y-0 left-0 h-screen px-1 py-0 pt-0 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-r-lg"}>

                                        <div className="sticky top-0 z-20 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900">



                                            {currentPlayer.name === "" && (
                                                <p className="flex items-center text-white px-4 py-2 rounded-md px-3 py-2 text-sm font-medium">
                                                    Select a Player from the Dropdown Menu
                                                </p>
                                            )}

                                            <div className="container mx-auto my-2">

                                                <Search onComponentSelect={handleComponentSelect} />
                                            </div>


                                            {currentPlayer.name !== "" && (

                                                <p className=" text-white px-3 py-2 rounded-lg text-1xl font-semibold whitespace-nowrap">
                                                    {currentPlayer.name}
                                                </p>

                                            )}
                                            <p className=" text-white px-3 py-2 rounded-lg text-1xl font-semibold whitespace-nowrap">
                                                Indian Premier League
                                            </p>

                                        </div>

                                        <p className="flex items-center text-2xl font-semibold whitespace-nowrap text-gray-400 hover:text-white hover:bg-gray-700 px-4 py-2 mt-2 border-t border-gray-700" onClick={() => setTournamentOpen(!tournamentOpen)}>
                                            {tournamentOpen && (<svg viewBox="0 0 24 24" className="w-6 h-6 mr-2 fill-current text-gray-400">
                                                <path d="M12 4.6L7.6 9H11v11h2V9h3.4L12 4.6zM22 13h-4v-2h4v2zm0-4h-4V7h4v2z"></path>
                                            </svg>)}
                                            {!tournamentOpen && (<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-6 h-6 mr-2 fill-current text-gray-400">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 4.5h14.25M3 9h9.75M3 13.5h9.75m4.5-4.5v12m0 0l-3.75-3.75M17.25 21L21 17.25" />
                                            </svg>)}
                                            Tournament

                                        </p>
                                        <ul className="mt-4">
                                            {tournamentOpen && (

                                                <li key='IPL' className='hover:text-xl hover:font-semibold rounded-lg text-left mx-3 text-gray-100' title="Select Tournament">
                                                    <p className="hover:text-xl hover:font-semibold rounded-lg text-left mx-3">Indian Premier League</p>
                                                </li>
                                            )}

                                        </ul>


                                        <p className="flex items-center text-2xl font-semibold whitespace-nowrap text-gray-400 hover:text-white hover:bg-gray-700 px-4 py-2 mt-2 border-t border-gray-700" onClick={toggleDropdown}>
                                            {isDropdownOpen && (<svg viewBox="0 0 24 24" className="w-6 h-6 mr-2 fill-current text-gray-400">
                                                <path d="M12 4.6L7.6 9H11v11h2V9h3.4L12 4.6zM22 13h-4v-2h4v2zm0-4h-4V7h4v2z"></path>
                                            </svg>)}
                                            {!isDropdownOpen && (<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-6 h-6 mr-2 fill-current text-gray-400">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 4.5h14.25M3 9h9.75M3 13.5h9.75m4.5-4.5v12m0 0l-3.75-3.75M17.25 21L21 17.25" />
                                            </svg>)}
                                            Team Cards


                                        </p>


                                        <ul key={'temp'} className="mt-4 z-0">

                                            {isListLoading && <Loading/>}

                                            {isDropdownOpen && (
                                                <div key={'a'}
                                                    className="z-0 text-white cursor-pointer relative rounded-lg flex flex-col">
                                                    <div key={'b'} className=" z-0 dropdown absolute top-full left-0 bg-gray-00 w-full overflow-x-auto flex flex-col space-y-4 mb-4">
                                                        {teamData.map(team => (
                                                            <div key={team.short}>
                                                                <li key={team.short + '1'} className={`mx-0  text-white rounded-lg bg-gradient-to-r from-blue-600 via-blue-500 to-yellow-400 hover:opacity-100 opacity-70 shadow-md`}>
                                                                    <p key={team.short + '4'} className="rounded-lg py-1 text-2xl font-semibold whitespace-nowrap overflow-x-auto">{team.short}</p>

                                                                    <div key={team.short + '2'} className={`h-64 hover:from-blue-600 via-blue-500 to-yellow-400 rounded-lg overflow-y-scroll hover:opacity-100 bg-current-${getActiveClass(team.theme)}`}>
                                                                        <ul key={team.short + '3'} className='flex flex-col mt-3'>
                                                                            {team.players.map((player, i) => {
                                                                                return (
                                                                                    <li key={player.name} className='hover:text-xl hover:font-semibold rounded-lg text-left mx-3' title="Select to view stats" onClick={() => { changePlayer(player); }}>
                                                                                        {
                                                                                            (player.id !== "unknown" && player.id !== "TBD") && (
                                                                                                player.name

                                                                                            )
                                                                                        }

                                                                                    </li>
                                                                                )

                                                                            })}
                                                                        </ul>
                                                                    </div>
                                                                </li>

                                                            </div>

                                                        ))

                                                        }
                                                    </div>
                                                </div>
                                            )}

                                        </ul>

                                    </aside>

                                </div>
                        

                            )}


                            <div className="ml-auto z-0  w-12/12 md:w-10/12 md:px-3 px-1 py-2 overflow-y-auto">

                                <div id="Info Bar" className="flex mx-5 mb-3 md:my-3 rounded-lg shadow-lg bg-gray-300 bg-opacity-0 overflow-x-auto">
                                    
                                    {isLoadingMeta ? <div className="w-full justify-center align-middle"> <Loading/> </div> : 

                                    <div className="w-full grid grid-cols-2 md:grid-rows-1 grid-flow-row md:grid-flow-col gap-2 overflow-x-auto">
                                        {Object.keys(metaData).map((s) => {


                                            if (s !== "Born") {
                                                return (
                                                    <div key={s} className="md-1 w-50 h-30 overflow-y-auto">
                                                        <Card key={s + "1"} data={{ title: s, value: metaData[s] }} />
                                                    </div>
                                                )
                                            }
                                            else {
                                                return (
                                                    null
                                                )
                                            }

                                        })}
                                    </div>
                                    }
                                </div>

                                {(currentPlayer.name !== "" && isMobile() === false) &&
                                    <div  className='invisible sm:visible'>

                                        <div id="Menu Bar" className=" mx-5 rounded-lg shadow-lg bg-gray-300 bg-opacity-0 py-2 px-5 h-14  grid-flow-row grid-cols-2 md justify-center gap-2 md:gap-auto md:justify-end ">











                                            <div
                                                className="md:w-32 md:h-8 w-32 h-9 flex items-center dark:bg-white bg-gray-300 rounded-lg p-1 cursor-pointer"
                                                onClick={() => {
                                                    setIsBatSwitch(!isBatSwitch);
                                                }}
                                            >

                                                <div
                                                    className={
                                                        "z-10 bg-black md:w-16 md:h-6 h-6 w-16 text-gray-200 rounded-lg shadow-md transform duration-300 ease-in-out bg-blend-exclusion" +
                                                        (isBatSwitch ? null : toggleClass)
                                                    }
                                                >
                                                    {isBatSwitch ? "Bat" : "Bowl"}



                                                </div>
                                                <div className={
                                                    "z-0 md:w-16 md:h-6 h-6 w-16 text-gray-500 transform duration-300 ease-in-out bg-blend-exclusion" +
                                                    (isBatSwitch ? null : "md:transform -translate-x-14 transform -translate-x-14")
                                                }>
                                                    {isBatSwitch ? "Bowl" : "Bat"}
                                                </div>

                                            </div>



                                        </div>
                                    </div>
                                }

                                <div  className={isOpen ? "invisible md:visible" : "visible"}>
                                    {currentPlayer.name !== "" &&

                                        <div id="Menu Bar"  className={'flex mx-5 rounded-lg shadow-lg bg-gray-300 bg-opacity-90 py-2 px-2 md:h-30 md:justify-start'}>


                                            {currentPlayer.name !== "" && isLoading && (

                                                <div id="2" className="items-center justify-center h-inherit w-full">


                                                    <Loading />
                                                </div>)}
                                            {currentPlayer.name !== "" && !isLoading && (
                                                <div className="w-full flex  grid md:grid-rows-1 md:grid-cols-4 grid-flow-row grid-cols-2 md gap-1 md:gap-auto md:justify-start justify-center gap-y-4">

                                                    <div className="overflow-hidden bg-blend-overlay hidden md:visible">



                                                        <div
                                                            className="md:w-32 md:h-8 w-32 h-9 flex items-center bg-white rounded-lg p-1 cursor-pointer"
                                                            onClick={() => {
                                                                setIsBatSwitch(!isBatSwitch);
                                                            }}
                                                        >

                                                            <div
                                                                className={
                                                                    "bg-black md:w-16 md:h-6 h-6 w-16 text-gray-200 rounded-lg shadow-md transform duration-300 ease-in-out bg-blend-exclusion" +
                                                                    (isBatSwitch ? null : toggleClass)
                                                                }
                                                            >
                                                                {isBatSwitch ? "Bat" : "Bowl"}


                                                            </div>


                                                        </div>

                                                    </div>
                                                    <div className="align-center mx-auto w-36 ">

                                                        <div  className="justify-center max-h-10 bg-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 rounded-lg px-1 font-semibold pt-1 ">
                                                            <div className="text-xs">Match Range</div>
                                                            <RangeSlider onMatchRange={handleSlider} range={originalMatchRange} max={originalPlayerData.length} onChange={handleSliderChange}/>

                                                        </div>
                                                    </div>


                                                    <div className="w-36 mx-auto bg-white rounded-lg  ring-gray-300 hover:bg-gray-50  shadow-sm ring-1 ring-inset">
                                                        <DropDownPanel title={'Venue'} values={[...new Set(originalPlayerData.map(item => item.Venue))]} selectedValues={venues} setSelectedValues={setVenues} />

                                                    </div>

                                                    <div className="z-0">



                                                        <Popover as="div" className="relative inline-block">
                                                            {({ open }) => (
                                                                <>




                                                                    <div className="w-36 bg-white rounded-lg  ring-gray-300 hover:bg-gray-50  shadow-sm ring-1 ring-inset">
                                                                        <Popover.Button className="inline-flex w-full justify-center  justify-right rounded-md px-1 py-2 text-sm font-semibold text-gray-900">
                                                                            Opponent
                                                                            {/* <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" /> */}
                                                                        </Popover.Button>
                                                                    </div>

                                                                    <Transition
                                                                        as={Fragment}
                                                                        enter="transition ease-out duration-100"
                                                                        enterFrom="transform opacity-0 scale-95"
                                                                        enterTo="transform opacity-100 scale-100"
                                                                        leave="transition ease-in duration-75"
                                                                        leaveFrom="transform opacity-100 scale-100"
                                                                        leaveTo="transform opacity-0 scale-95"
                                                                    >
                                                                        <Popover.Panel static className="absolute  w-20 max-h-60 overflow-y-auto right-0 z-10 mt-2 w-auto origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                                                                            <div className="flex sticky  py-1">

                                                                                <div
                                                                                    onClick={() => setFilterOpponents([])}
                                                                                    href="#"
                                                                                    className={classNames(
                                                                                        'bg-gray-300 hover:bg-gray-100 text-gray-900 flex block px-4 mx-1 py-2 text-sm rounded-lg'
                                                                                    )}
                                                                                >   <span className="inline-block mr-2">


                                                                                        <p>None</p>
                                                                                    </span>


                                                                                </div>
                                                                                <div
                                                                                    onClick={() => setFilterOpponents([...opponents])}
                                                                                    href="#"
                                                                                    className={classNames(
                                                                                        'bg-gray-300 hover:bg-gray-100 text-gray-900 flex block mx-1 px-4 py-2 text-sm rounded-lg'
                                                                                    )}
                                                                                >   <span className="inline-block mr-2">


                                                                                        <p>All</p>
                                                                                    </span>


                                                                                </div>


                                                                            </div>

                                                                            {opponents.map((item) => (

                                                                                <div key={item} className="flex py-1">


                                                                                    <div key={item + "1"}
                                                                                        onClick={() => handleOpponentFilter(item)}
                                                                                        href="#"
                                                                                        className={
                                                                                            'hover:bg-gray-100 hover:text-gray-900 flex text-gray-700 block px-4 py-2 text-sm flex'
                                                                                        }
                                                                                    >   <span key={item + "span"} className="inline-block mr-2">


                                                                                            <p key={item + "p"}>{item}</p>

                                                                                        </span>
                                                                                        {(filterOpponents.includes(item)) && (
                                                                                            <span className="inline-block">
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-4 h-4">
                                                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /> abc
                                                                                                </svg>

                                                                                            </span>
                                                                                        )}

                                                                                    </div>


                                                                                </div>
                                                                            ))}


                                                                        </Popover.Panel>
                                                                    </Transition>
                                                                </>
                                                            )}
                                                        </Popover>
                                                    </div>



                                                    <div className="inline">



                                                        <Menu as="div" className="relative inline-block min-w-30">




                                                            <div className="z-3 w-36 justify-center ">
                                                                <Menu.Button className="inline-flex w-full z-3 justify-center  rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                                                    Filter By
                                                                    {/* <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" /> */}
                                                                </Menu.Button>
                                                            </div>

                                                            <Transition
                                                                as={Fragment}
                                                                enter="transition ease-out duration-100"
                                                                enterFrom="transform opacity-0 scale-95"
                                                                enterTo="transform opacity-100 scale-100"
                                                                leave="transition ease-in duration-75"
                                                                leaveFrom="transform opacity-100 scale-100"
                                                                leaveTo="transform opacity-0 scale-95"
                                                            >
                                                                <Menu.Items className="absolute right-0 z-10 mt-2 w-auto origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                    <div className="flex py-1">
                                                                        <Menu.Item key={"0"}>
                                                                            {({ active }) => (
                                                                                <div
                                                                                    onClick={() => setBatFirst(!batFirst)}
                                                                                    href="#"
                                                                                    className={classNames(
                                                                                        active ? 'bg-gray-100 text-gray-900 flex' : 'text-gray-700',
                                                                                        'block px-4 py-2 text-sm flex'
                                                                                    )}
                                                                                >   <span className="inline-block mr-2">


                                                                                        <p>Bat First</p>
                                                                                    </span>
                                                                                    {batFirst && (
                                                                                        <span className="inline-block">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-4 h-4">
                                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /> abc
                                                                                            </svg>

                                                                                        </span>
                                                                                    )}

                                                                                </div>
                                                                            )}
                                                                        </Menu.Item>
                                                                        <Menu.Item key={"1"}>
                                                                            {({ active }) => (
                                                                                <div
                                                                                    onClick={() => setBatSecond(!batSecond)}
                                                                                    href="#"
                                                                                    className={classNames(
                                                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                        'block px-4 py-2 text-sm flex'
                                                                                    )}
                                                                                >
                                                                                    <span className="inline-block mr-2">
                                                                                        <p>Bat Second</p>

                                                                                    </span>

                                                                                    {batSecond && (
                                                                                        <span className="inline-block">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-4 h-4">
                                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /> abc
                                                                                            </svg>

                                                                                        </span>
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                        </Menu.Item>
                                                                    </div>
                                                                    <div className="flex py-1">
                                                                        <Menu.Item key={"2"}>
                                                                            {({ active }) => (
                                                                                <div
                                                                                    onClick={() => setWin(!win)}
                                                                                    href="#"
                                                                                    className={classNames(
                                                                                        active ? 'bg-gray-100 text-gray-900 flex' : 'text-gray-700',
                                                                                        'block px-4 py-2 text-sm flex'
                                                                                    )}
                                                                                >   <span className="inline-block mr-2">


                                                                                        <p>Wins</p>
                                                                                    </span>
                                                                                    {win && (
                                                                                        <span className="inline-block">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-4 h-4">
                                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /> abc
                                                                                            </svg>

                                                                                        </span>
                                                                                    )}

                                                                                </div>
                                                                            )}
                                                                        </Menu.Item>
                                                                        <Menu.Item key={"3"}>
                                                                            {({ active }) => (
                                                                                <div
                                                                                    onClick={() => setLose(!lose)}
                                                                                    href="#"
                                                                                    className={classNames(
                                                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                        'block px-4 py-2 text-sm flex'
                                                                                    )}
                                                                                >
                                                                                    <span className="inline-block mr-2">
                                                                                        <p>Loses</p>

                                                                                    </span>

                                                                                    {lose && (
                                                                                        <span className="inline-block">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-4 h-4">
                                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /> abc
                                                                                            </svg>

                                                                                        </span>
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                        </Menu.Item>
                                                                    </div>
                                                                </Menu.Items>
                                                            </Transition>
                                                        </Menu>
                                                    </div>

                                                </div>




                                            )}


                                        </div>
                                    }
                                </div>

                                {currentPlayer.name === "" && (
                                    <div  className="">

                                        <div className="h-full container flex md:items-center justify-center md:my-5">
                                            <p className="md:animate-bounce md:text-4xl text-2xl font-bold text-gray-900 dark:text-black">Player Stats Dashboard</p>

                                        </div>
                                        <div className={`flex container md:my-2 z-0 px-20 md:mb-10   justify-center md:visible invisible `}>

                                            <Search onComponentSelect={handleComponentSelect} className="" />
                                        </div>
                                        <div>
                                            <Demo />
                                        </div>
                                    </div>


                                )}

                                {currentPlayer.name !== "" && !isLoading && (

                                    <div {...handlers} className=" bg-gray-100 bg-opacity-95 h-fit py-5 px-5 my-5 mx-5 shadow-lg rounded-lg">






                                        <>

                                            {isBatSwitch ?
                                                (<BattingStats playerData={playerData} isOpen={isOpen} metaData={metaData} />) : (<BowlingPage bowlerData={playerData} isOpen={isOpen} />)}


                                        </>



                                    </div>

                                )}






                            </div>
                        </div>
                    </div>
                    <div className="bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 text-gray-400 text-sm text-right bg-opacity-30">
                        Last Updated: {lastUpdated}
                    </div>



                </div>




            </div>


        </>

    )
}

export default DashboardHome;
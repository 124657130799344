import { Link, useLocation } from "react-router-dom";
import { Disclosure } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'

const navigation = [
  { name: 'Player Dashboard', href: '#', to: "/dashboard/", current: false },
  // { name: 'Team Dashboard', href: '#', to: "/teamboard", current: false },
  { name: 'Players', href: '/players', to: "/players", current: false },
  { name: 'About', href: '#', to: "/about", current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function NavBar() {

    const location = useLocation();
    const currentPath = location.pathname;
    
    // ... rest of the component code

  return (
    <Disclosure as="nav" className="bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900">
      {({ open }) => (
        <>
          <div className="max-w-7xl px-2 sm:px-6 lg:px-2 md:px-5">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
                
              </div>
              
              

              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start  md:w-3/12 md:ml-12">
                <Link to={'/'}>
                <div className="flex flex-shrink-0 items-center hover:bg-blue-600 rounded-lg">
                  <p className='tracking-[.1em] text-slate-100 px-1 text-2xl font-semibold whitespace-nowrap'>CricDash</p>
                </div>
                </Link>
                <div className="hidden md:ml-auto sm:ml-6 sm:block ml-auto md:w-10/12">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                        
                      <div
                        key={item.name}
                        
                        className={classNames(
                          item.to === currentPath  ? 'bg-gray-700 text-white font-semibold' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'rounded-md px-3 py-2 text-sm font-medium'
                        )}
                        aria-current={item.to === currentPath ? 'page' : undefined}
                      >
                        <Link to={item.to}>{item.name}</Link>
                      </div>
                      
                    ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                

                {/* Profile dropdown */}
                
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3">
              {navigation.map((item) => (
                <Link to={item.to} key={item.to}>
                <Disclosure.Button
                  key={item.name}
                  as="p"
             
                  className={classNames(
                    item.to === currentPath ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block rounded-md px-3 py-2 text-base font-medium'
                  )}
                  aria-current={item.to === currentPath ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
                </Link>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

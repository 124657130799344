import React, { useState, useRef, useEffect, useMemo } from 'react';
import axios from 'axios';

// const components = [
//   'Component A',
//   'Component B',
//   'Component C',
//   'Component D',
//   'Component E',
// ];




// const components = [
//   { ID: 1, Name: 'Component A' },
//   { ID: 2, Name: 'Component B' },
//   { ID: 3, Name: 'Component C' },
//   { ID: 4, Name: 'Component D' },
//   { ID: 5, Name: 'Component E' },
// ];

const Search = ({ onComponentSelect }) => {

    const [components, setComponents] = useState([{'ID':"0", 'Name':"0"}]);

    useEffect(() => {
        const loadPlayerData = async () => {

            const response = await axios.get(`/api/playerslist`);
            const playersList = response.data;
            setComponents(playersList);
        }
        loadPlayerData();
       

    }, [])



  const [query, setQuery] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const dropdownRef = useRef(null);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
    setShowSuggestions(true);
  };

  const handleSuggestionClick = (component) => {
    setQuery("");
    setShowSuggestions(false);
    onComponentSelect([component.ID, component.Name]);
  };

  const filteredComponents = useMemo(() => {
    return components.filter((component) =>
      component.Name.toLowerCase().includes(query.toLowerCase())
    );
  }, [components, query]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setShowSuggestions(false);
      }
    };

    const handleFocusOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    document.addEventListener('focusin', handleFocusOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('focusin', handleFocusOutside);
    };
  }, []);

  return (
    <div className="relative w-full" ref={dropdownRef}>
      <input
        type="text"
        value={query}
        onChange={handleInputChange}
        className="md:block sm:z-0 sm: w-full px-4 py-2 text-lg leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
        placeholder="Search"
      />
      {showSuggestions && (
        <div className="absolute z-0 w-full max-h-60 overflow-y-auto mt-2 bg-white rounded shadow-md font-sans font-light">
          {filteredComponents.map((component) => (
            <div
              key={component.ID}
              className="px-4 py-2 cursor-pointer hover:bg-gray-200"
              onClick={() => handleSuggestionClick(component)}
            >
              {component.Name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Search;

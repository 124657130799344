export default function Card({data}) {

    return(
        <div
            className="min-w-1 rounded-lg shadow-xs overflow-hidden bg-gray-800"
        >
            <div className=" content-center p-3 overflow">
    
            <div>
                <p className="mb-2 text-xs font-medium text-gray-400 dark:text-gray-400">
                {data.title}
                </p>
                <p className="text-xs font-semibold text-gray-200">{data.value} </p>
            </div>
            </div>
        </div>
    )
}
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';



function valuetext(value) {
  return `${value}°C`;
}

export default function RangeSlider({ onMatchRange, range, max }) {
  const [value, setValue] = useState([range[0], range[1]]);
  

  const handleChange = (event, newValue) => {

    event.stopPropagation(); 
    
    if(onMatchRange){
    setValue(newValue);
    onMatchRange(newValue);
    }
    
  };

  return (
    <Box sx={{ zIndex: 0 }}>

      <Slider sx={{ zIndex: 0 }}
        getAriaLabel={() => 'Match Range'}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        className='z-index:0'
        min={0}
        max={max}
      />
    </Box>
  );
}
